<script>
// import Select2 from 'vue3-select2-component';
import useVuelidate from "@vuelidate/core";
import {
    required,
    helpers,
} from "@vuelidate/validators";
import axios from 'axios';
import _ from 'lodash';
import CryptoJS from 'crypto-js';
import Footer from "@/components/footer/footer.vue";

export default {
    setup() {
        return { v$: useVuelidate() }
    },
    data() {
        return {
            app_URL: process.env.VUE_APP_URL,
            resizedImageDataUrl: null,
            showErrorUpload: false,
            submitted: false,
            disclosureForm: {
                fullname: '',
                residentialAddress: '',
                bornAt: '',
                marriedAt: '',
                marriedTo: '',
                unmarriedChildren: '',
                unmarriedChildrenList: [],
                citizenshipOfCountries: [],
                britishProtectedReason: [],
                alienForeignCountry: '',
                formerlyCitizenCountry: [],
                formerlyCitizenCountryReason: [],
                ordinaryResident: '',
                inService: '',
                serviceOfCommonwealth: [{ 'capacityOfService': '', from: '', to: '' }, { 'capacityOfService': '', from: '', to: '' }, { 'capacityOfService': '', from: '', to: '' }],
                dateOfPermit: '',
                refNoOfPermit: '',
                countryOfResidence: '',
                capacityOfServices: '',
                specialCircumstance: [],
                referenceName: '',
                referenceAddress: '',
                closeEquitanceYear: '',
                refDate: '',
                ref20: '',
                applicantSignature1: '',
                applicantSignature2: '',
                justiceCommissioner: '',
                appropriateSubscribe: '',
                appropriateDayOf: '',
                appropriateBefore: '',
            },

        }
    },
    components: {
        // Select2
        Footer
    },
    validations: {
        disclosureForm: {
            fullname: {
                required: helpers.withMessage("This field is required", required),
            },
            alienForeignCountry: {
                required: helpers.withMessage("This field is required", required),
            },
            // ordinaryResident: {
            //     required: helpers.withMessage("This field is required", required),
            // },
            // inService: {
            //     required: helpers.withMessage("This field is required", required),
            // },
            dateOfPermit: {
                required: helpers.withMessage("This field is required", required),
            },
            refNoOfPermit: {
                required: helpers.withMessage("This field is required", required),
            },
            // applicantSignature1: {
            //     required: helpers.withMessage("This field is required", required),
            // },
            // applicantSignature2: {
            //     required: helpers.withMessage("This field is required", required),
            // },
        },
    },
    // emits: ['select'], // Declare the emitted event 'select'
    created() {
        // this.getDisclosureForm();
    },
    // mounted() {
    //     this.getDisclosureForm();
    // },

    methods: {
        addFormD12() {
            this.submitted = true;
            this.v$.$touch();
            if (this.v$.$invalid) {
                return false;
            } else {
                let data = new FormData();
                data.append('user_id', atob(decodeURIComponent(JSON.parse(localStorage.getItem('authenticatedUserId')))));
                data.append('disclosure_id', this.decode(this.$route.params.id));
                data.append('applicantSignature1', this.applicantSignature1);
                data.append('applicantSignature2', this.applicantSignature2);
                data.append('justiceCommissioner', this.justiceCommissioner);
                // Flatten the serviceOfCommonwealth object
                this.disclosureForm.serviceOfCommonwealth.forEach((item, index) => {
                    data.append(`serviceOfCommonwealth[${index}][capacityOfService]`, item.capacityOfService);
                    data.append(`serviceOfCommonwealth[${index}][from]`, item.from);
                    data.append(`serviceOfCommonwealth[${index}][to]`, item.to);
                });

                // Flatten other properties of the disclosureForm object
                _.each(this.disclosureForm, (value, key) => {
                    if (key !== 'serviceOfCommonwealth') {
                        data.append(key, value);
                    }
                });
                // _.each(this.disclosureForm, (value, key) => {
                //     data.append(key, value);
                // });
                axios.post(this.app_URL + 'api/addFormD12', data, { responseType: 'arraybuffer' }).then((res) => {
                    let blob = new Blob([res.data], { type: 'application/pdf' })
                    let link = document.createElement('a')
                    link.href = window.URL.createObjectURL(blob)
                    link.download = 'FormD12.pdf'
                    link.click()
                    this.$store.state.isAccepted = true;
                    this.$store.state.notificationData = 'Form 12 Saved and Downloaded Successfully';
                    setTimeout(() => {
                        this.$store.state.isAccepted = false;
                        this.$store.state.notificationData = null;
                    }, 2500)
                    this.$router.push({ path: '/citizenship_application' });
                });

            }
        },
        intToRoman(num) {
            const romanNumerals = {
                M: 1000,
                CM: 900,
                D: 500,
                CD: 400,
                C: 100,
                XC: 90,
                L: 50,
                XL: 40,
                X: 10,
                IX: 9,
                V: 5,
                IV: 4,
                I: 1
            };

            let result = '';

            for (const symbol in romanNumerals) {
                while (num >= romanNumerals[symbol]) {
                    result += symbol;
                    num -= romanNumerals[symbol];
                }
            }

            return result;
        },
        commissioner(event) {
            this.justiceCommissioner = event.target.files[0];
        },
        handleImageUpload(event, i) {
            const file = event.target.files[0];
            if (file) {
                const image = new Image();
                image.onload = () => {
                    // if (!(image.width < 142 && image.height < 180 && image.width > 122 && image.height > 160)) {
                    //     // alert('Please upload images with dimensions of 35mm x 45mm.')
                    //     this.showErrorUpload = true;
                    //     // Remove the CSS class after a timeout (e.g., 2 seconds)
                    //     setTimeout(() => {
                    //         this.showErrorUpload = false;
                    //     }, 2800);
                    // }
                    // else {
                    this.disclosureForm['applicantSignature' + i] = event.target.files[0];
                    this.showErrorUpload = false;
                    const desiredWidthInPixels = 35 * (96 / 25.4); // 35mm converted to pixels
                    const desiredHeightInPixels = 45 * (96 / 25.4); // 45mm converted to pixels

                    // const aspectRatio = image.width / image.height;
                    console.log(image.width)
                    console.log(image.height)
                    let newWidth, newHeight;


                    newHeight = desiredHeightInPixels;
                    newWidth = desiredWidthInPixels;

                    const canvas = document.createElement('canvas');
                    canvas.width = newWidth;
                    canvas.height = newHeight;

                    const ctx = canvas.getContext('2d');
                    ctx.drawImage(image, 0, 0, newWidth, newHeight);

                    this.resizedImageDataUrl = canvas.toDataURL(file.type);

                    // Now you have the resized image data in the desired dimensions.
                    console.log(`Resized Image Dimensions: ${newWidth.toFixed(2)}px x ${newHeight.toFixed(2)}px`);
                    console.log(`Resized Image Type: ${file.type}`);
                    // }

                    // You can store or use resizedImageDataUrl as needed.
                };
                console.log(URL.createObjectURL(file))
                image.src = URL.createObjectURL(file);
            }
        },
        decode(id) {
            return decodeURIComponent(
                CryptoJS.AES.decrypt(String(id), "Secret Passphrase").toString(
                    CryptoJS.enc.Utf8
                )
            );
        }
    }
}
</script>
<template>
    <main class="pt-5 pb-3">
        <div class="container">
            <div class="card memberStep">
                <div class="card-body">
                    <div class="pb-4 text-center">
                        <img src="@/assets/images/citizenship/whinchester.png" alt="Logo whinchester" height="60" />
                    </div>
                    <h2 class="mainTitleSize">COMMONWEALTH OF DOMINICA CITIZENSHIP ACT <br>CH. 1:10 (Section 8)</h2>
                    <div class="">
                        <div class="">
                            <label>Application for naturalisation as a citizen of Domininca made by a person seeking a
                                waiver of residence requirements under section 8 (2) (c) of the Commonwealth of Dominica
                                Citizenship Act, 1978.</label>
                            <label>MADE under section 8 of the Act.</label>
                            <label>(N.B. Applicants are requested to submit (a) two (2) Completed copies of this Form; (b)
                                four (4) identical passport size photographs; (c) a police certificate of
                                character.)</label>
                            <h2 class="font-size-18 pb-2">PARTICULARS OF APPLICANT</h2>
                        </div>
                        <div class="main-from-group my-4">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="row">
                                        <div class="col-lg-12 mb-3">
                                            <div class="d-block mb-3">
                                                <label class="font-size-14"><span class="text-theme">1. </span> I
                                                    <input type="text" class="cstmInputDesign" placeholder=""
                                                        v-model="disclosureForm.fullname"
                                                        :class="{ 'is-invalid': submitted && v$.disclosureForm.fullname.$error, }">
                                                    <div v-for="(errorMsg, index) in v$.disclosureForm.fullname.$errors"
                                                        :key="index" class="invalid-feedback">
                                                        <span v-if="errorMsg.$message">{{ errorMsg.$message }}</span>
                                                    </div>
                                                    of <input type="text" class="cstmInputDesign" placeholder=""
                                                        v-model="disclosureForm.residentialAddress">
                                                    (here insert
                                                    address of applicant) was born at<input type="text"
                                                        class="cstmInputDesign" placeholder=""
                                                        v-model="disclosureForm.bornAt">
                                                </label>
                                            </div>
                                            <div class="d-block mb-3">
                                                <label class="font-size-14"><span class="text-theme">2. </span> I was
                                                    married at <input type="text" class="cstmInputDesign" placeholder=""
                                                        v-model="disclosureForm.marriedAt"> to
                                                    <input type="text" class="cstmInputDesign" placeholder=""
                                                        v-model="disclosureForm.marriedTo">(here insert
                                                    full name of (wife) (husband) (of late of)
                                                    (here insert wife's) (husband's) address, or last address if deceased).
                                                </label>
                                            </div>
                                            <div class="d-block mb-3">
                                                <label class="font-size-14"><span class="text-theme">3. </span> . My
                                                    marriage (is still subsisting) has been terminated by reason of (If the
                                                    marriage is not subsisting, state whether it was terminated by the
                                                    husband's death or by divorce.
                                                </label>
                                            </div>
                                            <div class="d-block mb-3">
                                                <label class="font-size-14"><span class="text-theme">4. </span> There are
                                                    <input type="text" class="cstmInputDesign w-25" placeholder=""
                                                        v-model="disclosureForm.unmarriedChildren">
                                                    unmarried children below the age of 18, of the marriage namely (here
                                                    list names, addresses and date of birth of children)
                                                    <input v-for="(child, index) in 4" :key="index" type="text"
                                                        class="form-control mb-3" placeholder=""
                                                        v-model="disclosureForm.unmarriedChildrenList[index]">
                                                </label>
                                            </div>
                                            <div class="d-block mb-3">
                                                <label class="font-size-14"><span class="text-theme">5. (1) </span> I am a
                                                    Citizen of the following countries:</label>
                                                <template v-for="(child, index) in 3" :key="index">
                                                    <div class="d-flex align-items-center">
                                                        <label class="font-size-14 me-3">({{ intToRoman(child) }})</label>
                                                        <input type="text" class="form-control mb-3" placeholder=""
                                                            v-model="disclosureForm.citizenshipOfCountries[index]">
                                                    </div>
                                                </template>
                                                <p>*Delete which is inapplicable.</p>
                                            </div>

                                            <div class="d-block mb-3">
                                                <label class="font-size-14"><span class="text-theme">**(2)</span> I am
                                                    British protected person by reason of the following facts:</label>
                                                <input v-for="(child, index) in 4" :key="index" type="text"
                                                    class="form-control mb-3" placeholder=""
                                                    v-model="disclosureForm.britishProtectedReason[index]">

                                            </div>

                                            <div class="d-block mb-3">
                                                <label class="font-size-14"><span class="text-theme">(3) </span>I am an
                                                    alien and a subject or citizen of the following foreign
                                                    country/countries, that is to say:
                                                </label>
                                                <input type="text" class="form-control mb-3" placeholder=""
                                                    v-model="disclosureForm.alienForeignCountry"
                                                    :class="{ 'is-invalid': submitted && v$.disclosureForm.alienForeignCountry.$error, }">
                                                <div v-for="(errorMsg, index) in v$.disclosureForm.alienForeignCountry.$errors"
                                                    :key="index" class="invalid-feedback">
                                                    <span v-if="errorMsg.$message">{{ errorMsg.$message }}</span>
                                                </div>
                                            </div>

                                            <div class="d-block mb-3">
                                                <label class="font-size-14"><span class="text-theme">(4). (a) </span>I am a
                                                    stateless alien and was formerly a citizen of the following country or
                                                    countries: </label>
                                                <template v-for="(child, index) in 3" :key="index">
                                                    <div class="d-flex align-items-center">
                                                        <label class="font-size-14 me-3">({{ intToRoman(child) }})</label>
                                                        <input type="text" class="form-control mb-3" placeholder=""
                                                            v-model="disclosureForm.formerlyCitizenCountry[index]">
                                                    </div>
                                                </template>
                                            </div>

                                            <div class="d-block mb-3">
                                                <label class="font-size-14"><span class="text-theme">***(b)</span> I have
                                                    ceased to be a citizen of the above country/countries by reason of the
                                                    following facts: </label>
                                                <input v-for="(child, index) in 4" :key="index" type="text"
                                                    class="form-control mb-3" placeholder=""
                                                    v-model="disclosureForm.formerlyCitizenCountryReason[index]">
                                            </div>

                                            <div class="d-block mb-3">
                                                <label class="font-size-14"><span class="text-theme">**</span> State in the
                                                    space under (b) the protectorate or territory from which the applicant
                                                    derives the status of a British protected person. </label>
                                            </div>

                                            <div class="d-block mb-3">
                                                <label class="font-size-14"><span class="text-theme">***</span> State under
                                                    (b) the cause whereby the applicant ceased to be a subject or citizen of
                                                    the country or countries in question, whether the country is one
                                                    mentioned in section 1 (3) of the British Nationality Act, 1948 or is
                                                    the Republic of Ireland or is a foreign country.</label>
                                            </div>

                                            <div class="d-block mb-3">
                                                <label class="font-size-14"><span class="text-theme">(5) </span> I am a
                                                    stateless alien and have always been stateless.</label>
                                            </div>

                                            <div class="d-block mb-3">
                                                <label class="font-size-14 d-flex align-items-start"><span
                                                        class="text-theme">6. </span>*(a) I am ordinarily resident in
                                                    <input type="text" class="cstmInputDesign" placeholder=""
                                                        v-model="disclosureForm.ordinaryResident"></label>

                                                <!-- <div v-for="(errorMsg, index) in v$.disclosureForm.ordinaryResident.$errors"
                                                    :key="index" class="invalid-feedback">
                                                    <span v-if="errorMsg.$message">{{ errorMsg.$message }}</span>
                                                </div> -->
                                                <!-- <input type="text" class="cstmInputDesign" placeholder=""> -->

                                            </div>

                                            <div class="d-block mb-3">
                                                <label class="font-size-14 d-flex align-items-start"><span
                                                        class="text-theme">(b) </span> I am in the service of the
                                                    Commonwealth of Dominica.
                                                </label>
                                                <!-- <div v-for="(errorMsg, index) in v$.disclosureForm.inService.$errors"
                                                    :key="index" class="invalid-feedback">
                                                    <span v-if="errorMsg.$message">{{ errorMsg.$message }}</span>
                                                </div> -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="groupFormStyle mt-4">
                                <h2 class="font-size-18 pb-2">SERVICE OF THE COMMONWEALTH OF DOMINICA</h2>
                                <div class="d-block mb-4">
                                    <div class="d-block mb-3">
                                        <label class="font-size-14 d-flex align-items-start text-nowrap"><span
                                                class="text-theme text-nowrap">7. (1)(a)(i) </span> Capacity of Service
                                            <input type="text" class="cstmInputDesign" placeholder=""
                                                v-model="disclosureForm.serviceOfCommonwealth[0].capacityOfService">
                                        </label>
                                    </div>
                                </div>

                                <div class="d-block mb-4">
                                    <div class="d-block mb-3">
                                        <label class="font-size-14 d-flex align-items-start text-nowrap"><span
                                                class="text-theme text-nowrap">(ii) </span> From <input type="text"
                                                v-model="disclosureForm.serviceOfCommonwealth[0].from"
                                                class="cstmInputDesign" placeholder=""> to <input type="text"
                                                class="cstmInputDesign" placeholder=""
                                                v-model="disclosureForm.serviceOfCommonwealth[0].to">
                                        </label>
                                    </div>
                                </div>

                                <div class="d-block mb-4">
                                    <div class="d-block mb-3">
                                        <label class="font-size-14 d-flex align-items-start text-nowrap"><span
                                                class="text-theme text-nowrap">(b)(i) </span> Capacity of Service <input
                                                type="text" class="cstmInputDesign" placeholder=""
                                                v-model="disclosureForm.serviceOfCommonwealth[1].capacityOfService">
                                        </label>
                                    </div>
                                </div>

                                <div class="d-block mb-4">
                                    <div class="d-block mb-3">
                                        <label class="font-size-14 d-flex align-items-start text-nowrap"><span
                                                class="text-theme text-nowrap">(ii) </span> From <input type="text"
                                                v-model="disclosureForm.serviceOfCommonwealth[1].from"
                                                class="cstmInputDesign" placeholder=""> to <input type="text"
                                                v-model="disclosureForm.serviceOfCommonwealth[1].to" class="cstmInputDesign"
                                                placeholder="">
                                        </label>
                                    </div>
                                </div>

                                <div class="d-block mb-4">
                                    <div class="d-block mb-3">
                                        <label class="font-size-14 d-flex align-items-start text-nowrap"><span
                                                class="text-theme text-nowrap">(c)(i) </span> Capacity of Service <input
                                                v-model="disclosureForm.serviceOfCommonwealth[2].capacityOfService"
                                                type="text" class="cstmInputDesign" placeholder="">
                                        </label>
                                    </div>
                                </div>

                                <div class="d-block mb-4">
                                    <div class="d-block mb-3">
                                        <label class="font-size-14 d-flex align-items-start text-nowrap"><span
                                                class="text-theme text-nowrap">(ii) </span> From <input type="text"
                                                v-model="disclosureForm.serviceOfCommonwealth[2].from"
                                                class="cstmInputDesign" placeholder=""> to <input type="text"
                                                v-model="disclosureForm.serviceOfCommonwealth[2].to" class="cstmInputDesign"
                                                placeholder="">
                                        </label>
                                    </div>
                                </div>

                                <div class="d-block mb-4">
                                    <div class="d-block mb-3">
                                        <label class="font-size-14"><span class="text-theme">(2) (a) </span>I have been
                                            granted permission to reside temporarily in Dominica.
                                        </label>
                                    </div>
                                </div>

                                <div class="d-block mb-3">
                                    <label class="font-size-14"><span class="text-theme">(b) </span>Date of Permit</label>
                                    <input type="date" class="form-control" v-model="disclosureForm.dateOfPermit"
                                        placeholder=""
                                        :class="{ 'is-invalid': submitted && v$.disclosureForm.dateOfPermit.$error, }">
                                    <div v-for="(errorMsg, index) in v$.disclosureForm.dateOfPermit.$errors" :key="index"
                                        class="invalid-feedback">
                                        <span v-if="errorMsg.$message">{{ errorMsg.$message }}</span>
                                    </div>
                                </div>
                                <div class="d-block mb-3">
                                    <label class="font-size-14"><span class="text-theme">(c) </span>Ref: No. of
                                        Permit</label>
                                    <input type="text" class="form-control" v-model="disclosureForm.refNoOfPermit"
                                        placeholder=""
                                        :class="{ 'is-invalid': submitted && v$.disclosureForm.refNoOfPermit.$error, }">
                                    <div v-for="(errorMsg, index) in v$.disclosureForm.refNoOfPermit.$errors" :key="index"
                                        class="invalid-feedback">
                                        <span v-if="errorMsg.$message">{{ errorMsg.$message }}</span>
                                    </div>
                                </div>

                                <div class="d-block mb-3">
                                    <label class="font-size-14"><span class="text-theme"></span>State the country of
                                        residence.</label>
                                    <input type="text" class="form-control" v-model="disclosureForm.countryOfResidence"
                                        placeholder="">
                                    <!-- <div v-for="(errorMsg, index) in v$.disclosureForm.firstName.$errors" :key="index"
                                        class="invalid-feedback">
                                        <span v-if="errorMsg.$message">{{ errorMsg.$message }}</span>
                                    </div> -->
                                </div>

                                <div class="d-block mb-3">
                                    <label class="font-size-14"><span class="text-theme"></span>State capacity of service/s
                                        in Government (e.g. Diplomatic/Consular) or other giving date/s of appointment.
                                    </label>
                                    <textarea type="textarea" class="form-control resize-none" rows="8" placeholder=""
                                        v-model="disclosureForm.capacityOfServices"></textarea>
                                </div>

                            </div>


                            <h2 class="font-size-18 pb-2 mt-4">SPECIAL CIRCUMSTANCES</h2>

                            <div class="d-block mb-3">
                                <label class="font-size-14"><span class="text-theme"></span>The special circumstances in
                                    which I desire that the period of ordinary residence be waived are: </label>
                                <input type="text" v-for="(child, index) in 4" :key="index" class="form-control mb-3"
                                    placeholder="" v-model="disclosureForm.specialCircumstance[index]">
                            </div>

                            <div class="d-block mb-3">
                                <label class="font-size-14"><span class="text-theme">***8.</span> I (have) (have not)
                                    previously renounced or been deprived of citizenship of the United Kingdom and Colonies,
                                    or of the Commonwealth of Dominica. (If the applicant has renounced his citizenship of
                                    the United Kingdom and Colonies, here state the date on which the declaration or
                                    renunciation was made; and if he has been deprived of his citizenship, state the date on
                                    which the authority by whom, the order or deprivation was made). </label>
                            </div>

                            <div class="d-block mb-3">
                                <label class="font-size-14"><span class="text-theme">9. </span> I have read the caution at
                                    the foot of this application.</label>
                            </div>

                            <div class="d-block mb-3">
                                <label class="font-size-14"><span class="text-theme">10. </span> I hereby apply to be
                                    registered as a citizen of Dominica. </label>
                                <div class="d-block mb-3">
                                    <label class="font-size-14"> I
                                        <input type="text" class="cstmInputDesign" placeholder=""
                                            v-model="disclosureForm.fullname"
                                            :class="{ 'is-invalid': submitted && v$.disclosureForm.fullname.$error, }"> do
                                        solemnly and sincerely
                                        declare that the forgoing particulars stated in this application are true and I make
                                        this solemn declaration believing the same to be true.

                                    </label>
                                    <div v-for="(errorMsg, index) in v$.disclosureForm.fullname.$errors" :key="index"
                                        class="invalid-feedback">
                                        <span v-if="errorMsg.$message">{{ errorMsg.$message }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-8"></div>
                                <div class="col-4">
                                    <div class="d-block mb-3 mt-5">
                                        <input type="file" class="form-control mb-3" placeholder=""
                                            @change="handleImageUpload($event, 1)">
                                        <!-- <div v-for="(errorMsg, index) in v$.disclosureForm.applicantSignature1.$errors"
                                            :key="index" class="invalid-feedback">
                                            <span v-if="errorMsg.$message">{{ errorMsg.$message }}</span>
                                        </div> -->
                                        <label class="font-size-14">(Signature of the Applicant) </label>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-12">
                                    <div class="d-block mb-3 mt-5">
                                        <label class="font-size-14"><i>Delete as appropriate</i></label>
                                    </div>

                                    <div class="d-block mb-3">
                                        <label class="font-size-14 d-flex align-items-start text-nowrap">Made and subscribed
                                            this
                                            <input type="text" v-model="disclosureForm.appropriateSubscribe"
                                                class="cstmInputDesign w-25" placeholder=""> day of <input type="text"
                                                v-model="disclosureForm.appropriateDayOf" class="cstmInputDesign"
                                                placeholder="">20<input type="text"
                                                v-model="disclosureForm.appropriateBefore" class="cstmInputDesign w-25"
                                                placeholder="">before me.
                                        </label>
                                    </div>
                                    <div class="d-block mb-3 text-center m-auto w-50 mt-5">
                                        <input @change="commissioner($event)" type="file" class="form-control mb-3"
                                            placeholder="">
                                        <label class="font-size-14 d-flex align-items-start text-nowrap">(Justice of the
                                            peace, Commissioner, Notary Public).
                                        </label>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <h2 class="font-size-18 pb-2 mt-4">REFERENCE</h2>
                                    <div class="d-block mb-3">
                                        <label class="font-size-14 d-flex align-items-start text-nowrap">Name (in block
                                            letters)
                                            <input type="text" class="cstmInputDesign w-25" placeholder=""
                                                v-model="disclosureForm.referenceName"> Address<input type="text"
                                                class="cstmInputDesign" placeholder=""
                                                v-model="disclosureForm.referenceAddress">
                                        </label>
                                    </div>
                                    <div class="d-block mb-3">
                                        <label class="font-size-14 ">I, the undersigned, hereby state that I am a citizen of
                                            Dominica. I support this application from any personal knowledge of the
                                            applicant and my close acquaintance with him for <input type="text"
                                                class="cstmInputDesign w-25"
                                                v-model="disclosureForm.closeEquitanceYear">years.
                                        </label>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="d-block mb-3">
                                        <label class="font-size-14 ">Date <input type="text"
                                                v-model="disclosureForm.refDate" class="cstmInputDesign w-25">20<input
                                                type="text" v-model="disclosureForm.ref20" class="cstmInputDesign w-25">
                                        </label>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-8"></div>
                                    <div class="col-4">
                                        <div class="d-block mb-3 mt-5">
                                            <input type="file" class="form-control mb-3" placeholder=""
                                                @change="handleImageUpload($event, 2)">
                                            <!-- <div v-for="(errorMsg, index) in v$.disclosureForm.applicantSignature2.$errors"
                                                :key="index" class="invalid-feedback">
                                                <span v-if="errorMsg.$message">{{ errorMsg.$message }}</span>
                                            </div> -->
                                            <label class="font-size-14">(Signature of the Applicant) </label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <h2 class="font-size-18 pb-2 mt-4">CAUTION</h2>
                                    <div class="d-block mb-3">
                                        <label class="font-size-14 d-flex align-items-start text-nowrap">Section 19 of the
                                            Commonwealth of Dominica Citizenship Act (Ch.1:10) provides as follows: </label>
                                    </div>
                                    <div class="d-block mb-3">
                                        <label class="font-size-14 ">“19.(1) A person who, for the purpose of procuring
                                            anything to be done or not to be done under this Act, knowingly or recklessly
                                            makes a statement which is false in a material particular shall be without
                                            prejudice to any other proceedings that might be taken against him, liable on
                                            summary conviction to a fine of seven hundred and fifty dollars and to
                                            imprisonment for three months.
                                        </label>
                                    </div>
                                    <div class="d-block mb-3">
                                        <label class="font-size-14 ">(2) Any person who fails to comply with any requirement
                                            imposed on him by Regulations made under this Act shall be liable to a fine of
                                            seven hundred and fifty dollars and to imprisonment for six months.”
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="text-end">
                            <button type="button" @click="addFormD12()" class="btn btn-theme">Submit</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
    <Footer></Footer>
</template>