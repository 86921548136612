<template>
  <footer class="footer">
    <div class="container">
      <div class="row">
        <div class="col-sm-6 text-sm-start text-center">{{ new Date().getFullYear() }} <strong class="fw-medium">©
            ECU.</strong></div>
        <div class="col-sm-6">
          <div class="text-sm-end text-center">Design & Develop by <strong class="fw-medium">MISPL</strong></div>
        </div>
      </div>
      <div class="toast-modal-area toast-modal-custom toast-top-right ">
        <div v-if="this.$store.state.isAccepted" class="toast show toast-success" aria-live="polite">
          <div class="success-image-style image-style-toast">
            <img src="@/assets/images/check-success-icon.png" alt="check">
          </div>
          <div class="toast-message">{{ this.$store.state.notificationData }}</div>
        </div>
        <div v-if="this.$store.state.isDeclined" class="toast show toast-danger" aria-live="polite">
          <div class="success-image-style image-style-toast">
            <img src="@/assets/images/error-cancel.png" alt="check">
          </div>
          <div class="toast-message">{{ this.$store.state.notificationData }}</div>
        </div>
      </div>
    </div>
  </footer>
  <!-- end footer -->
</template>
  